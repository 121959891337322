$colors: (
  '0': #ffffff,
  '1': rgb(74, 136, 210),
  '2': rgb(141, 180, 226),
  '3': rgb(197, 217, 241),
  '4': rgb(247, 150, 70),
  '5': rgb(250, 191, 143),
  '6': rgb(252, 219, 192),
  '7': rgb(79, 194, 60),
  '8': rgb(143, 206, 74),
  '9': rgb(187, 209, 139),
  '10': rgb(146, 122, 174),
  '11': rgb(177, 160, 199),
  '12': rgb(222, 216, 232),
  '13': rgb(166, 166, 166),
  '14': rgb(191, 191, 191),
  '15': rgb(213, 213, 213),
  '16': rgb(255, 202, 33),
  '17': rgb(255, 255, 79),
  '18': rgb(255, 129, 129),
  '19': rgb(255, 0, 255),
);

@each $name, $color in $colors {
  .job-color-#{$name} {
    background-color: $color !important;
  }
}
