@use '@angular/material' as mat;
/* You can add global styles to this file, and also import other style files */
@use 'ag-grid-community/styles' as ag;

@import 'fontsource-montserrat/index.css';
@import 'variables';
@import '@angular/material/theming';
/*@import 'ag-grid-community/styles/ag-grid.css';*/

@import 'job-colors';
@import 'absence-colors';
@import 'tailwind';

@include ag.grid-styles(
  (
    theme: material,
  )
);

// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// created with http://mcg.mbitson.com/
$md-kallisto-accent: (
  50: #f6faf5,
  100: #e9f3e5,
  200: #dbebd4,
  300: #cce3c2,
  400: #c1ddb5,
  500: #b6d7a8,
  600: #afd3a0,
  700: #a6cd97,
  800: #9ec78d,
  900: #8ebe7d,
  A100: #ffffff,
  A200: #ffffff,
  A400: #e7ffde,
  A700: #d4ffc4,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$md-kallisto-warn: (
  50: #fdf5f5,
  100: #fbe6e6,
  200: #f8d6d6,
  300: #f5c6c6,
  400: #f2b9b9,
  500: #f0adad,
  600: #eea6a6,
  700: #ec9c9c,
  800: #e99393,
  900: #e58383,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #fff0f0,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$md-kallisto-primary: (
  50: #fff1e8,
  100: #ffdcc5,
  200: #ffc49e,
  300: #ffac77,
  400: #ff9b5a,
  500: #ff893d,
  600: #ff8137,
  700: #ff762f,
  800: #ff6c27,
  900: #ff591a,
  A100: #ffffff,
  A200: #fffefe,
  A400: #ffd8cb,
  A700: #ffc5b2,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$primary: mat.define-palette($md-kallisto-primary);
$accent: mat.define-palette($md-kallisto-accent);
$warn: mat.define-palette($md-kallisto-warn);

$custom-typography: mat.define-typography-config(
  $font-family: 'Montserrat, monospace',
);

// Create the theme object (a Sass map containing all of the palettes).
$candy-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    ),
    density: -1,
  )
);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($candy-app-theme);

@include mat.all-component-typographies($custom-typography);

@import '@angular/cdk/overlay-prebuilt.css';

.mat-mdc-tab-link {
  color: $color-text-light;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-label {
  color: $color-text-label;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-legacy .mat-form-field-label {
  color: $color-text-label;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Montserrat, 'Helvetica Neue', sans-serif;
  // this is needed for the cordova webview
  background-color: white;
}

nav {
  background-color: $color-navbar;
}

app-planning-grid {
  .scrollable-content {
    height: unset !important;
    max-height: unset !important;
    min-height: 100vh !important;
  }
}

.mat-icon {
  color: $actionColor;
}

.ag-theme-material {
  font-family: $font-family;

  .ag-header-cell-text {
    color: $color-text-label;
    font-size: 14px;
    font-weight: 600;
  }

  .ag-header-row {
    font-weight: normal;
  }

  .ag-row {
    color: $color-text-dark;
  }
}

// https://stackoverflow.com/questions/35578083/what-is-the-equivalent-of-ngshow-and-nghide-in-angular-2
[hidden] {
  display: none !important;
}

.snack-bar-error {
  background-color: red;
  color: white;
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: none;
}

.mat-mdc-fab.white {
  background-color: white;
}

// add this class to a mat-form-field to keep the text color of an input readable if the element is disabled
.disabled-visibility {
  .mat-mdc-input-element:disabled {
    color: rgba(0, 0, 0, 0.87);
  }
}

.td-dialog-actions {
  .mat-mdc-button.mat-accent {
    color: black;
    background-color: $eblOrange;
  }

  button {
    text-transform: none !important;
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
}

input[type='text']:focus,
textarea[type='text']:focus,
textarea:focus {
  @apply border-0 ring-0;
}

// Make form-fields look like before v15 update
.mat-mdc-form-field {
  .mdc-text-field--filled:not(.mdc-text-field--disabled),
  .mdc-text-field--disabled.mdc-text-field--filled,
  .mat-mdc-form-field-focus-overlay {
    background-color: transparent;
  }

  .mat-mdc-text-field-wrapper,
  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    padding: 0;
  }

  .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control,
  .mat-mdc-form-field-icon-suffix,
  .mdc-floating-label {
    font-size: 14px;
  }

  .mat-mdc-select {
    font-size: 14px;
    padding: 0;
  }

  .mat-mdc-form-field-bottom-align::before {
    height: 0;
  }
}
