$color-navbar: #333333;
$color-employee-task-planned-confirmed: #d8ebcf;
$color-employee-task-emergency: #e6b0ae;
$color-employee-task-pending: #f0f0f0;

$color-box-background: #f0f0f0;
$color-background: #ffffff;

$color-text-light: #ffffff;
$color-text-dark: #000000;
$color-text-label: #2b2b2b;
$color-text-muted: #aaaaaa;

$eblOrange: #ff893d;
$primaryColor: #b6d7a8;
$actionColor: #757575;
$font-family: Montserrat, 'Helvetica Neue', sans-serif;

$color-wp-running: #d8ebcf;
$color-wp-paused: #ebcfcf;
$color-wp-completed: #cad2eb;

$color-sidebar-bg: #e5e5e5;
$color-sidebar: #757575;

$grid-bg-color: #e2e2e2;

$tablet-screen-size: 1024px;
